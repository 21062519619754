$plan1Color: #ff920d;
$plan2Color: #7524f2;
$plan3Color: #00c79f;

.container {
  width: 100%;
  max-width: 350px;

  padding: var(--padding-sm);
  background: var(--background-default);
  border-radius: var(--round-box-border-radius);
  border: 1px solid var(--neutral-lightest);
  transition: 0.3s ease-in;

  .title {
    text-align: left;
    color: var(--neutral-darkest);

    h1 {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      font-weight: bold;
      color: var(--secondary-pure);

      p {
        margin: 0 0.5rem 0 0;
        font-size: 1rem;
      }
    }
  }

  h6 {
    color: var(--neutral-dark);
    font-size: 1rem;

    span {
      float: right;
      font-size: 1.2rem;
    }
  }

  .advantages {
    ul {
      color: var(--neutral-dark);
      list-style: none;

      li {
        margin-top: 1rem;
        display: flex;
        align-items: center;

        &::before {
          content: url("/check.svg");
          margin-right: 0.5rem;
        }
      }
    }
  }

  .benefits {
    color: var(--neutral-dark);
    line-height: 1.5rem;

    ul {
      padding: 0.5rem 0 0 1.5rem;
    }
  }

  hr {
    margin: 1rem 0;
    border: 1px solid var(--neutral-lightest);
    background: var(--neutral-lightest);
  }

  a {
    height: var(--field-height);
    max-width: 150px;
  }

  &:nth-of-type(1) {
    border-color: $plan1Color;

    .title {
      color: $plan1Color;
    }
    h1 {
      color: $plan1Color;
    }
    a {
      background-color: $plan1Color !important;
    }
    button {
      background-color: $plan1Color;
    }
  }

  &:nth-of-type(2) {
    border-color: $plan2Color;

    .title {
      color: $plan2Color;
    }
    h1 {
      color: $plan2Color;
    }
    a {
      background-color: $plan2Color !important;
    }
    button {
      background-color: $plan2Color;
    }
  }

  &:nth-of-type(3) {
    border-color: $plan3Color;

    .title {
      color: $plan3Color;
    }
    h1 {
      color: $plan3Color;
    }
    a {
      background-color: $plan3Color !important;
    }
    button {
      background-color: $plan3Color;
    }
  }
}

@media (max-width: 992px) {
  .container {
    min-width: 38vw;
    &:hover {
      transform: scale(1);
    }
  }
}

@media (max-width: 589px) {
  .container {
    width: 80vw;
  }
}
