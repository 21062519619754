.fadeInSection {
  opacity: 0;
  transform: translateY(6vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  -moz-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  -ms-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  -o-transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fadeInSection.isVisible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
