.card {
  width: 100%;
  max-width: 325px;
  margin: var(--size-xs);
  text-align: center;

  .imgContainer {
    position: relative;
    margin: auto;
    margin-bottom: -3rem;
    width: 95px;
    height: 95px;
    background-color: white;
    border-radius: 50%;
  }

  .cardContent {
    padding: 4rem 1.5rem 1.5rem 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    background-color: var(--white);
    border-radius: 12px;
    transition: color 0.15s ease, border-color 0.15s ease;

    &:hover,
    &:focus,
    &:active {
      color: #1f2020;
      border-color: #0070f3;
    }
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    height: 50px;
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--gray-9);
    height: 108px;
  }

  .knowMore {
    color: #0070f3;
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 100%;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}
