.simulationContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: var(--padding-sm);
  background: var(--background-alternate);
  border-radius: var(--round-box-border-radius);

  form {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--padding-sm);

    .formLine {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 2rem;
      margin-bottom: var(--size-lg);

      &:last-of-type {
        margin-bottom: 0;
      }

      .switcher,
      .field {
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        width: 50%;
        min-width: 300px;

        > label,
        > p {
          margin: 0 0 0.5rem 0;
        }

        &.occupation > div > div:last-of-type {
          display: none;
        }

        .errorContainer {
          display: none;
        }
      }

      .switcher {
        div:first-child {
          width: 100%;
        }
      }

      &.submitBtn button {
        margin: 0;
      }
    }
  }

  label {
    display: block;
    text-align: center;
    font-size: 1rem;
    color: var(--font-color);
  }

  select {
    height: var(--field-height-sm);
  }

  .selectSex button {
    border-radius: var(--border-radius-xlg);
    height: var(--field-height-sm);
  }
}

.planCards {
  min-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  align-items: start;
  justify-items: center;
}

@media (max-width: 992px) {
  .simulationContent {
    form {
      .field {
        min-width: 200px;
      }
    }
  }

  .planCards {
    max-width: 100%;
    overflow-x: scroll;
    grid-gap: 2rem;
    padding: 2rem 0;
  }
}

@media (max-width: 768px) {
  .simulationContent {
    form {
      width: 100%;
      flex-direction: column;

      .field,
      .switcher {
        min-width: 100%;
      }

      .formLine {
        flex-direction: column;
        margin-bottom: 2rem;

        .field,
        .switcher {
          width: 100%;
        }
      }
    }
  }

  .planCards {
    padding: 0;
  }
}
