.question {
  background: var(--background-alternate);
  border-radius: var(--box-border-radius);
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 1rem;
  height: auto;
  cursor: pointer;
}

.titleQuestion {
  display: flex;
  align-items: center;

  h6 {
    font-weight: normal;
  }

  svg {
    margin: 0 1rem 0 auto;
    color: var(--secondary-color);
    height: 50px;
    transition: all 1s ease-out;
  }
}

.answer {
  color: var(--neutral-dark);
  margin-left: 10px;

  a {
    color: var(--link-color);
  }
}
