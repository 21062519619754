.footerContainer {
  width: 100%;
  color: var(--white);
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  svg {
    height: 30px;
    width: 35px;
    cursor: pointer;
  }
}

.footerContent {
  width: 100%;
  display: grid;
  row-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 2fr 2fr;
  justify-items: flex-start;

  a {
    color: var(--white);
    text-decoration: underline;
  }

  .footerLogo {
    grid-row: 1/3;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    div {
      grid-row: span 1;
      margin: 1rem 0;

      display: flex;
      flex-direction: column;
    }

    div:last-child {
      img {
        margin-top: 5px;
      }
    }
  }

  .footerLinks {
    width: 100%;
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    align-content: center;
    justify-content: flex-start;

    a {
      width: 100%;
      height: 40px;
      grid-row: span 1;

      display: flex;
      align-items: center;
    }
  }

  .socialLinks {
    margin-top: 2rem;
    width: 50%;
    display: grid;
    align-content: center;
    justify-content: flex-start;
    grid-template-columns: 1fr 1fr 1fr 0fr;

    .socialLink {
      width: 50px;
      height: 50px;

      grid-column: span 1;
      border-radius: 10px;
      border: 1px solid var(--white);

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .contactUs {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 1rem;
    padding-top: 2rem;
    grid-column: 1/3;
    justify-self: center;
    border-top: 1px solid var(--white);

    div:first-of-type {
      display: flex;
    }
  }

  .contact {
    width: 50%;
  }

  .copyrightMessage {
    width: 100%;
    margin: 2rem 0 0.1rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footerContent {
    row-gap: 2.5rem;

    .contactUs {
      margin-top: 0;
    }
  }
}

@media (max-width: 576px) {
  .footerContent {
    div {
      grid-column: span 2;
    }

    .contactUs {
      div:first-of-type {
        display: inline;
      }
    }

    .contact {
      width: 100%;
    }

    .socialLinks {
      width: 100%;
    }
  }
}
