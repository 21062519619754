.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 0.5rem;

  -ms-flex-direction: row;

  label {
    color: var(--gray-8);
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
}

.buttonRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  div {
    width: 50%;

    button {
      margin: 0;
      background-color: var(--background-default);

      svg {
        margin-right: 0.5rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .errorContainer {
    width: 100%;
    min-height: 16px;
    flex: 1;
  }
}
