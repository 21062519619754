.rangeInput {
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    height: 20px;
    width: 25px;
    color: var(--neutral-pure);
  }
}

.round {
  border-radius: var(--border-radius-xlg);
}
