.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .switcher {
    width: fit-content;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: var(--white);

    border: 1px solid var(--neutral-light);
    border-radius: var(--border-radius-xlg);
    padding: 2px;
    white-space: nowrap;

    div {
      position: relative;
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: inherit;
      padding: 0 var(--padding-md);
      cursor: pointer;

      p {
        z-index: 1;
      }

      div {
        padding: 0;
      }
    }

    .selected {
      position: absolute;
      z-index: 0;
    }

    .selectedText {
      color: var(--white);
      transition: color 50ms ease-in 25ms;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .switcher {
      text-overflow: ellipsis;
    }
  }
}
