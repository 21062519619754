.rangeInput {
  width: 100%;

  input[type="range"] {
    appearance: none;
    height: 7px;
    width: 100%;
    border: none;
    padding: 0;
    background: var(--neutral-lightest);
    cursor: pointer;

    &::-moz-range-track,
    &::-ms-track,
    &::-webkit-slider-runnable-track {
      width: 100%;
      cursor: pointer;

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-thumb {
      border: none;
      height: var(--size-sm);
      width: var(--size-sm);
      border-radius: 50%;
      background: var(--secondary-pure);
      cursor: pointer;
    }
  }

  .currentValue {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--padding-sm);
    margin-bottom: -6px;
    margin-top: -6px;

    h3 {
      font-weight: bold;
      color: var(--neutral-darkest);
    }
  }

  .rangeLabel {
    display: flex;
    justify-content: space-between;
    color: var(--neutral-pure);

    span {
      font-size: 0.75rem;
    }
  }
}
