.curvedFooterContainer {
  .curve {
    height: 60px;
    background: url("../../../public/topFooter.svg");
    margin-bottom: -10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
}
