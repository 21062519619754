.container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;

  & > label {
    color: var(--neutral-pure);
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  .errorContainer {
    width: 100%;
    min-height: 16px;
    flex: 1;
  }

  .inputError > input,
  .inputError > select,
  .inputError > textarea {
    border: 1px solid var(--error-color) !important;
    border-radius: var(--field-border-radius);
  }

  .errorMsg {
    font-size: 0.75rem;
    margin: 0.25rem 0 0 0.75rem;
    color: var(--error-color);
  }
}
