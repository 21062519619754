$p-section: 4rem;

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-alternate);

  section {
    width: 100%;
  }
}

.title,
.titleSection,
.descriptionSection,
.description {
  text-align: center;
  width: 100%;
}

.titleSection {
  margin: 0;
  line-height: 1.15;
  font-size: 2rem;
  font-weight: bold;
}

.descriptionSection {
  color: var(--neutral-dark);
  margin-bottom: 3rem;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 3rem;

  span {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.buttonContainer {
  max-width: 350px;
  margin: auto;
}

.fadeInOpacity {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner {
  margin-top: var(--size-xl);
  width: 100%;
  background: var(--primary-color-1);
  background: url("/bgTop.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .contentBanner {
    z-index: 2;
    margin-top: 4rem;
    width: 100%;

    button:first-of-type {
      max-width: 200px;
      height: var(--field-height-lg);
    }
  }

  .animationBanner {
    margin-top: -4rem;
    z-index: 0;
    width: 100%;
  }
}

.benefits {
  padding: $p-section 0;

  .benefitsCards {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }
}

.simulation {
  width: 100%;
  min-height: 500px;
  padding-top: 3rem;
  background: var(--background-default);

  h2 {
    margin: 2rem 0;
  }
}

.easyHire {
  width: 100%;
  background: var(--white);
  padding: $p-section 0 0 0;

  .titleSection {
    margin-bottom: 3rem;
  }

  .easyHireContent {
    display: flex;

    p {
      color: var(--gray-7);
      flex: 1;
      justify-content: flex-end;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    p:first-of-type {
      text-align: right;
      justify-content: center;
    }

    .easyHireAnimation {
      flex: 2;
      z-index: 2;
      min-height: 340px !important;
      margin-bottom: -5rem;

      div:first-of-type {
        padding: 0 1rem;
      }
    }
  }
}

.digital {
  padding: 8rem 0 $p-section 0;

  .titleSection {
    padding: 0 0 3rem 0;
  }

  .digitalContent {
    text-align: left;
    display: flex;

    .digitalContentItem {
      padding: 0 1rem;
    }
  }
}

.plans {
  background: var(--white);

  .titleSection {
    margin: $p-section 0 3rem 0;
  }
  .planCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    background-color: var(--background-alternate);
    border-radius: var(--box-border-radius);
    padding: $p-section 1rem;

    .price {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      div:first-of-type {
        max-width: 200px !important;
      }

      h2 {
        margin: 1rem 0;
        color: var(--secondary-color);
        font-weight: bold;

        span {
          color: var(--gray-7);
          font-size: 1rem;
        }
      }
    }

    .planCardContent {
      display: flex;
      width: 100%;

      div {
        width: 100%;
      }
    }
    ul {
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 2rem;
      color: #2b2b2b;
    }

    a:first-of-type {
      max-width: 250px;
    }
  }
}

.prize {
  background: var(--white);
  padding: $p-section 0 3rem 0;
  text-align: center;

  .prizeContent {
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 1.5rem 3rem;
    border-radius: 20px;
    margin-top: -1.2rem;

    h3 {
      font-weight: 500;
    }
  }
}

.questions {
  padding: $p-section 0;
  margin-bottom: -1.5rem;
  background: var(--white);

  .questionsContent {
    display: flex;
    width: 100%;

    div:nth-child(odd) {
      h2,
      p {
        text-align: left;
      }
    }

    .questionsDescription {
      width: 40%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .questionsBody {
      width: 60%;
    }
  }
}

.doctorImage {
  background: none;
  text-align: center;
  margin-bottom: -4rem;
  width: 100%;
  height: auto;

  div {
    width: 100%;
    height: auto;
  }
}

.footerCustomization {
  text-align: center;
  width: 100%;
  color: var(--white);
  background-color: var(--secondary-color);
  padding: 2rem;
  margin-bottom: -10px;

  h2 {
    padding: 1rem 0;
  }
  a:first-of-type {
    max-width: 250px;
  }
}

@media (max-width: 1024px) {
  .easyHire {
    .easyHireContent {
      .easyHireAnimation {
        margin-bottom: -10rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .benefits {
    .benefitsCards {
      grid-template-columns: 1fr 1fr;
    }
  }

  .digital {
    padding: 3rem 0 3rem 0;
  }
}

@media (max-width: 767px) {
  .container {
    .title {
      font-size: 1.5rem;
    }
  }

  .banner {
    .animationBanner {
      margin-top: -2.5rem;
    }

    .contentBanner {
      button:first-of-type {
        max-width: 150px;
        height: var(--field-height);
      }
    }
  }

  .digital {
    padding: 3rem 0 3rem 0;

    .titleSection {
      padding: 0 0 3rem 0;
    }

    .digitalContent {
      flex-direction: column;

      .digitalContentItem {
        margin-bottom: 1.5rem;
      }
    }
  }

  .easyHire {
    padding: 4rem 0 3rem 0;

    .easyHireContent {
      flex-direction: column-reverse;

      .easyHireAnimation {
        margin: 2rem;
        min-height: auto !important;
      }
    }
  }

  .planCardContent {
    flex-direction: column;

    .planBenefits {
      padding: 1rem 2rem;
    }
  }
  .questions {
    .questionsContent {
      flex-direction: column;

      .questionsDescription {
        width: 100%;
      }

      .questionsBody {
        width: 100%;
        padding: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
  .benefits {
    .benefitsCards {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 589px) {
  .benefits {
    .card {
      width: 90%;
    }
  }
}

@media (max-width: 516px) {
  .doctorImage {
    margin-bottom: -6rem;
  }
}

@media (max-width: 375px) {
  .banner {
    .animationBanner {
      margin-top: -2rem;
    }
  }

  .doctorImage {
    margin-bottom: -9rem;
  }
}
